.upload-image {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .upload-list-inline {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .ant-upload {
        width: 80%;
        .upload-button {
          width: 100%;
        }
      }
    }
    .upload-list {
      width: 80%;
      .file-name {
        max-width: 3rem;
      }
    }
  }
  .files-support {
    width: 80%;
    font-weight: 400;
    font-size: 10px;
    color: #9cadc4;
    margin-top: 10px;
    text-align: left;
  }
}
.delivery-success-title {
  font-family: Be Vietnam Pro;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
}
