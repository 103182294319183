.user-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  span:nth-child(2) {
    font-weight: 700;
    font-size: 16px;
  }
  span:nth-child(3) {
    font-weight: 400;
    font-size: 12px;
  }
}

.menu-profile {
  margin-top: 32px;
  background-color: #ffffff;
  height: calc(100vh - 315px);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 0 10px;
  .ant-list-item {
    justify-content: flex-start;
    gap: 16px;
  }
}
