@import '../../styles/_mixinx.scss';
/* ============================== COMPUTER, LAPTOP ============================== */
.container {
  padding: 10px;
  .breadcrumb {
    height: 50px;
    max-width: 100%;
    padding: 8px 24px;
    border-radius: 16px;
    background-color: #ffffff;
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 16px;
    .crumb {
      font-weight: 500;
      font-size: 1.2rem;
    }
    .current {
      color: #2246dc;
      font-weight: 600;
    }
  }
  .content {
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/* ============================== MOBILE, TABLET ============================== */
@include mobile_portrait() {
  .container {
    .breadcrumb {
      padding: 8px;
      .crumb {
        font-size: 0.9rem;
      }
      .ant-breadcrumb-link{
        span{
          font-size: 16px;
        }
      }
    }
    .content {
      padding: 0px;
    }
  }
}
