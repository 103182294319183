.description-notification {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  .time {
    color: #2246dc;
    font-size: 14px;
    display: flex;
    gap: 8px;
    align-items: center;
  }
}
