@import '../../../../styles/_mixinx.scss';
/* ============================== COMPUTER, LAPTOP ============================== */
.container {
  width: 100%;
  display: grid;
  grid-template-columns: 40% calc(60% - 16px);
  grid-template-rows: 0.75fr 1.25fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  .card {
    padding: 16px 24px;
    background-color: #ffffff;
    border: 1px solid #dbdbf2;
    border-radius: 16px;
    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
    }

    .wrap-content {
      line-height: 20px;
      .sub-title {
        font-weight: 600;
        font-size: 18px;
        padding-bottom: 12px;
      }
      .content-text {
        font-weight: 400;
        font-size: 16px;
      }
      .tracking-event {
        padding-right: 300px;
      }
    }
  }

  .card-1 {
    grid-area: 1 / 1 / 1 / 3;

    .card-item {
      padding: 0;
      margin-top: 16px;
      .list-item {
        background: #f7f8fb;
        border-radius: 9px;
        padding: 10px 30px;
      }
      .text-info {
        .text-bold {
          font-weight: 600;
        }
        .text-blue {
          color: blue;
        }
      }
    }
  }
  .card-2 {
    grid-area: 2 / 1 / 2 / 1;

    .card-item {
      padding: 0 40px;
      margin-top: 16px;
      .list-item {
        background: #f7f8fb;
        border-radius: 9px;
        padding: 10px 30px;
      }
      .text-info {
        .text-bold {
          font-weight: 600;
        }
        .text-blue {
          color: blue;
        }
        .assets {
          width: 500px;
          .assets-list {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 12px;
            .img-video {
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
  .card-3 {
    .items {
      .item-cards {
        .mid {
          font-size: 12px;
        }
        .title {
          font-size: 14px;
          line-height: 24px;
        }
        .quantity {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
}

/* ============================== MOBILE, TABLET ============================== */

@include tablet_landscape() {
  .container {
    display: flex;
    flex-direction: column;
    grid-template-columns: 0;
    grid-column-gap: 0;
    grid-row-gap: 10px;
    .card {
      padding: 8px 16px;
      .title {
        font-size: 20px;
      }
      .wrap-content {
        line-height: 18px;
        .sub-title {
          font-weight: 600;
          font-size: 16px;
          padding-bottom: 12px;
        }
        .content-text {
          font-weight: 400;
          font-size: 14px;
        }
        .tracking-event {
          padding-right: 200px;
        }
      }
    }
  }
}

@include mobile_portrait() {
  .container {
    .card {
     .wrap-content{
       .tracking-event {
        padding-right: 0;
      }
     }
    }
    .order-required {
      .form-required {
        .form-wrapper {
          .form-right,
          .form-left {
            padding: 0px;
            .title {
              font-size: 14px;
            }
          }
        }
      }
    }
    .card-1,
    .card-2 {
      .card-item {
        padding: 0;
      }
    }
    .items {
      .item-cards {
        background: #f7f8fb;
        margin-top: 6px;
        border-radius: 8px;
        padding: 0 10px;
        .mid {
          font-size: 12px;
        }
        .title {
          font-size: 14px;
          line-height: 24px;
        }
        .right {
          float: right;
          .quantity {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

.modal-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px 90px;
  border-radius: 16px;
  .title,
  .description {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .title {
    display: flex;
    align-items: center;
    span {
      padding-left: 6px;
    }
  }
  .description {
    font-weight: 400;
    padding-top: 10px;
  }
  .group-button {
    display: flex;
    padding-top: 10px;
    .button {
      margin: 0 8px;
      border-radius: 8px;
      border: initial;
      color: white;
      cursor: pointer;
    }
    .cancel {
      background: #afbbca;
    }
    .confirm {
      background: blue;
    }
  }
}
