@import '../../../../styles/mixinx.scss';

/* ============================== COMPUTER, LAPTOP ============================== */
.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  height: 60px;
}

/* ============================== MOBILE, TABLET ============================== */

@include tablet_landscape() {
  .ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
    height: 0px;
  }
}
