.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #d9e7ff !important;
}
.ant-modal-content {
  border-radius: 16px !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px !important;
}
.ant-card-bordered {
  border: 0 !important;
}
.ant-card-body {
  padding: 0px !important;
}
