@import '../../../../styles/_mixinx.scss';
/* ============================== COMPUTER, LAPTOP ============================== */
.container {
  width: 100%;
  display: grid;
  grid-template-columns: 40% calc(60% - 16px);
  grid-template-rows: 0.75fr 1.25fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  .card {
    padding: 16px 24px;
    background-color: #ffffff;
    border: 1px solid #dbdbf2;
    border-radius: 16px;
    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
    }
  }

  .order-event {
    grid-area: 1 / 1 / 3 / 2;
    .title-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title-code {
        color: #2246dc;
        font-size: 16px;
        font-weight: 700;
      }
    }
    .code {
      margin: 8px 0px 24px 0px;
      .code-title {
        font-size: 15px;
        font-weight: 500;
        text-align: left;
      }
      .code-value {
        font-size: 18px;
        font-weight: 600;
        text-align: left;
      }
    }
    .button-group {
      margin-top: auto;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 24px;
      margin-bottom: 8px;
      .button {
        min-width: 100px;
        min-height: 50px;
        background-color: #ffffff;
        color: #005eff;
        border: 1px solid #005eff;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        border-radius: 8px;
      }
      .main-button {
        background-color: #005eff;
        color: #ffffff;
        border: 1px solid #005eff;
      }
    }
  }
  .order-detail {
    grid-area: 1 / 2 / 2 / 3;
    .info {
      margin-top: 8px;
      .info-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 8px;
      }
      hr {
        margin: 8px 0px;
      }
      .info-shipping {
        display: flex;
        justify-content: space-between;
        .info-shipping-values {
          text-align: right;
          float: right;
          div {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
  .order-items {
    grid-area: 2 / 2 / 3 / 3;
    .items-table {
      margin: 16px 0 24px 0;
      tbody > tr:hover > td {
        background: unset;
        background-color: #d9e7ff;
        color: #2676ff;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .total {
      text-align: right;
      .total-label {
        margin-right: 32px;
      }
      .total-value {
        font-size: 24px;
        font-weight: 600;
        color: #005eff;
      }
    }
    .items {
      .item-cards {
        width: 100%;
        background-color: #e9eef5;
        border-radius: 8px;
        margin: 7px 0px;
        padding: 7px;
        display: flex;
        flex-direction: row;
        gap: 5px;
        .right {
          margin-left: auto;
          word-break: break-all;
          text-align: right;
        }
        .title {
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
        }
        .quantity {
          font-size: 18px;
          line-height: 24px;
          color: #2246dc;
        }
      }
    }
  }
}

/* ============================== MOBILE, TABLET ============================== */

@include tablet_landscape() {
  .container {
    display: flex;
    flex-direction: column;
    grid-template-columns: 0;
    grid-column-gap: 0;
    grid-row-gap: 10px;
    .card {
      padding: 8px 16px;
      .title {
        font-size: 16px;
      }
    }
    .order-event {
      .button-group {
        .button {
          min-width: 50px;
          min-height: 25px;
          font-size: 14px;
          line-height: 0px;
        }
      }
    }
    .order-detail {
      .info {
        margin-left: 16px;
        font-size: 12px;
        margin-top: 0px;
        .info-title {
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 0px;
        }
        .info-shipping {
          .info-shipping-values {
            div {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
    .order-items {
      .total {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .total-label {
          margin-right: 0;
        }
      }
    }
  }
}

@include mobile_portrait() {
  .container {
    .order-event {
      .button-group {
        .button {
          min-width: 50px;
          min-height: 25px;
          font-size: 12px;
          line-height: 0px;
        }
      }
      .ant-steps-item-content {
        font-size: 12px;
      }
    }
    .order-items {
      .total {
        .total-label {
          font-size: 12px;
        }
        .total-value {
          font-size: 16px;
        }
      }
      .items {
        .item-cards {
          .mid {
            font-size: 12px;
          }
          .title {
            font-size: 14px;
            line-height: 24px;
          }
          .quantity {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
