@import '../../../../styles/_mixinx.scss';
/* ============================== COMPUTER, LAPTOP ============================== */
.container {
  .header {
    display: flex;
    flex-direction: row;
    gap: 12px;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .title {
      font-size: 14px;
      .green {
        color: #0eab10;
      }
      .red {
        color: #d2434d;
      }
    }
  }
  .order-status {
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .order-status-value {
      font-size: 14px;
      text-align: right;
    }
  }
  .order-status-title {
    font-size: 14px;
    font-weight: 700;
  }
}

/* ============================== MOBILE, TABLET ============================== */
@include mobile_portrait() {
  .container {
    .order-status {
      .order-status-value {
        font-size: 12px;
        text-align: right;
      }
    }
    .order-status-title {
      font-size: 12px;
      font-weight: 700;
    }
  }
}
