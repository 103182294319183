@import '../../styles/mixinx';
// ============================== LARGE SCREEN ==============================
.container {
  padding: 20px 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    width: 60px;
    height: 60px;
    cursor: pointer;
  }
  .buttonGroup {
    padding: 10px 0;
    display: flex;
    overflow-x: auto;
    max-width: calc(100vw - 120px);
    gap: 20px;
    direction: rtl;
  }
  .button {
    background: var(--color-7);
    padding: 0px 16px;
    height: 40px;
    border-radius: 8px;
    color: var(--color-3);
    font-weight: var(--font-weight-2);
    border: 1px solid var(--color-3);
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }
  .main-button {
    background: linear-gradient(135deg, #0934c5 0%, #4e64e3 100%);
    color: var(--color-7);
    border: 1px solid transparent;
  }
  .button:hover {
    cursor: pointer;
  }
}
// ============================== MOBILE & TABLET==============================
@include mobile_portrait() {
  .container {
    padding: 20px;
    .logo {
      width: 40px;
      height: 40px;
    }
  }
}

@include tablet_portrait() {
  .container {
    padding: 20px;
    .logo {
      width: 40px;
      height: 40px;
    }
  }
}

@include tablet_landscape() {
  .container {
    padding: 20px;
    .logo {
      width: 40px;
      height: 40px;
    }
  }
}
