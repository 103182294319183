@import '../../styles/_mixinx.scss';
// ============================== LARGE SCREEN ==============================
.container {
  padding: 1.5rem 1rem;
  margin: 0 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 0.25fr;
  background-color: #ffffff;
  border-radius: 16px;

  .download {
    display: flex;
    gap: 1rem;
    .qr-code {
      width: 120px;
      height: 120px;
      filter: drop-shadow(6px 6px 60px rgba(15, 26, 91, 0.14));
      border-radius: 8px;
      background-image: url('~/assets/images/temp-qr-code.png');
      background-size: cover;
    }
    .download-app {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .policies {
    display: grid;
    grid-template-columns: 1fr 1fr;

    a {
      color: #656c78;
    }
  }
  .logo {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: flex-start;
    .icon {
      width: 120px;
      height: 90px;
    }
    .subtitle {
      width: 250px;
      font-size: 1rem;
      color: #000000;
    }
  }
}
// ============================== MOBILE & TABLET==============================
@include tablet_landscape() {
  .container {
    grid-template-columns: 0.5fr 1fr;
    column-gap: 20px;
    .logo {
      gap: 10px;
      .subtitle {
        width: auto;
        font-size: 1rem;
      }
    }
  }
}

@include tablet_portrait() {
  .container {
    padding: 1rem;
    gap: 1rem;
  }
}

@include mobile_portrait() {
  .container {
    grid-template-columns: 1fr;
    .policies {
      gap: 1rem;
    }
    .logo {
      gap: 0px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .subtitle {
        text-align: center;
        font-size: 1rem;
        padding: 0 1.5rem;
      }
    }
  }
}

@include extra_small_mobile() {
  .container {
    .download {
      .qr-code {
        width: 90px;
        height: 90px;
      }
    }
  }
}
