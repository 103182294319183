@import '../../styles/mixinx';

.crumb {
  font-weight: 500;
  font-size: 1.2rem;
}
.current {
  color: #2246dc;
  font-weight: 600;
}
