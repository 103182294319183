@import '../../../../styles/_mixinx.scss';
/* ============================== COMPUTER, LAPTOP ============================== */
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 90px;
  .form {
    min-width: 150px;
    max-width: 500px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .title {
      font-size: 32px;
      font-weight: 600;
      line-height: 48px;
      margin-bottom: 15px;
    }
    .note {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #656c78;
      margin-bottom: 10px;
    }
    .input-field {
      .label {
        font-size: 15px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 4px;
      }
      .input {
        width: 100%;
        min-height: 50px;
        border-radius: 8px;
        padding: 14px 16px;
        border: none;
        background-color: #f3f3f7;
      }
      input:focus {
        outline: 2px solid #005eff;
      }
    }
    .reCaptcha {
      width: 100%;
    }
    .button-container {
      width: 100%;
      display: inline-block;
      .button {
        float: right;
        min-width: 100px;
        min-height: 50px;
        width: 50%;
        background-color: #005eff;
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        border-radius: 8px;
        border: none;
      }
    }
  }
  .tracking-sgv {
    width: 500px;
    height: 500px;
    .img {
      width: 100%;
      height: 100%;
    }
  }
}

/* ============================== MOBILE, TABLET ============================== */

@include tablet_landscape() {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    gap: 0;
    .form {
      padding: 0;
      .reCaptcha {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        .button {
          float: none;
        }
      }
    }
  }
}

@include mobile_portrait() {
  .container {
    .tracking-sgv {
      width: 350px;
      height: 350px;
    }
  }
}

@include extra_small_mobile() {
  .container {
    .tracking-sgv {
      width: 200px;
      height: 200px;
    }
  }
}
