@import 'normalize.scss';
@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@400&display=swap');
@import 'animate.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

:root {
  --color-1: #242533;
  --color-2: #a1a1b2;
  --color-3: #2246dc;
  --color-4: #656c78;
  --color-5: #dbdbf2;
  --color-6: #ff0c5c;
  --color-7: #ffffff;
  --color-8: #1d293f;
  --color-9: #4e64e3;
  --color-10: #97a0b3;
  --color-11: #15131d;
  --color-12: #f5f5fa;
  --color-13: #36374d;
  --font-weight-1: 400;
  --font-weight-2: 600;
  --font-weight-3: 700;
}

body {
  font-family: 'Anek Latin', sans-serif;
  line-height: 1.5rem;
  text-rendering: optimizeSpeed;
  color: var(--color-1);
  font-size: 16px;
  background-color: #f5f5fa;
}
