.modal-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .delete-icon {
    height: 60px;
    width: 60px;
    background-image: url('../../assets/images/delete-icon.PNG');
    background-size: 100% 100%;
  }

  .message {
    margin: 20px 0;
    text-align: center;
  }

  .modal-children {
    width: 100%;
  }

  .btn-group {
    button {
      min-width: 100px;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
