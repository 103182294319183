@import '../../styles/mixinx';
.container {
  margin: 0rem;
  background-color: #f5f5fa;
}
@include tablet_landscape() {
  .container {
    margin: 0;
  }
}
// style={{ margin: '0rem 3rem' }}
