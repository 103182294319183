@import '../../../../styles/mixinx.scss';
/* ============================== COMPUTER, LAPTOP ============================== */
.icon {
  position: absolute;
  top: -5px;
  left: -30px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: '';
}
.title {
  margin: 50px 0px;
}
.event-steps {
  // margin-left: 150px;
}
.description {
  position: absolute;
  left: -100px;
  top: -4px;
  text-align: center;
  font-size: 14px;
}
.address {
  color: #000000;
}

/* ============================== MOBILE, TABLET ============================== */

@include tablet_landscape() {
  .event-steps {
    margin-left: 0px;
  }
  .description {
    position: relative;
    left: 0px;
    text-align: left;
  }
}

@include mobile_portrait() {
  .title {
    font-size: 14px;
  }
  .event-steps {
    margin-left: 0px;
  }
  .description {
    font-size: 14px;
  }
}
