@mixin laptop() {
  @media only screen and (max-width: 1550px) {
    @content;
  }
}

@mixin tablet_landscape() {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

@mixin tablet_portrait() {
  @media only screen and (max-width: 991px) {
    @content;
  }
}

@mixin mobile_landscape() {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin mobile_portrait() {
  @media only screen and (max-width: 576px) {
    @content;
  }
}

@mixin extra_small_mobile() {
  @media only screen and (max-width: 350px) {
    @content;
  }
}
