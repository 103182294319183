.application-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  flex-wrap: wrap;
  padding: 0px 10px;
  .application-item {
    display: grid;
    justify-items: center;
    img {
      width: 60px;
    }
    span:nth-child(3) {
      color: #5b6673;
    }
  }
}
