.status-tabs {
  .status-list-container {
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
    overflow: auto;
    .adm-tabs-tab-line {
      background: #1953d8 !important;
    }
    .adm-tabs-tab {
      font-size: 14px;
      color: #1953d8;
    }
    .status-list {
      display: flex;
      flex-wrap: nowrap;
      transition: all 0.5s ease;
      align-items: center;
      .status-item {
        background-color: white;
        cursor: pointer;
        border-radius: 8px;
        padding: 10px 14px;
        margin-right: 5px;
        border: 1px solid #e0e0e0;
        white-space: nowrap;
        &:hover {
          border: 1px solid #275edb;
        }
      }

      .item-actived {
        border: 1px solid #275edb;
      }
    }
  }
}
