@import '../../../../styles/_mixinx.scss';
/* ============================== COMPUTER, LAPTOP ============================== */
.container {
  width: 100%;
  display: grid;
  grid-template-columns: 40% calc(60% - 16px);
  grid-template-rows: 0.75fr 1.25fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  .card {
    padding: 16px 24px;
    background-color: #ffffff;
    border: 1px solid #dbdbf2;
    border-radius: 16px;
    .required::after {
      content: ' *';
      color: red;
      font-size: 14px;
    }
    .title-card {
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
    }
    .wrap-content {
      line-height: 20px;
      .mt-10{
        margin-bottom: 10px;
      }
      .sub-title {
        font-weight: 600;
        font-size: 18px;
      }
      .content-text {
        font-weight: 400;
        font-size: 16px;
      }
      .order-value {
        font-weight: 600;
        color: #2246dc;
      }
    }
  }
  .order-required {
    grid-area: 2 / 1 / 2 / 3;
    .input-order-required {
      border: 1px solid #d8dfea;
      border-radius: 8px;
    }
    .form-required,
    .form-upload {
      width: 100%;
    }
    .form-left {
      flex: 100%;
    }
    .form-right {
      flex: 100%;
      padding-left: 150px;
      .text-red {
        color: red;
      }
    }
  }
  .order-detail {
    grid-area: 1 / 1 / 1 / 1;
  }
  .order-items {
    grid-area: 1 / 2 / 2 / 3;
    .items-table {
      margin: 16px 0 24px 0;
    }
  }
  .group-button {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    .button {
      background: var(--color-7);
      padding: 0px 16px;
      height: 40px;
      border-radius: 8px;
      color: var(--color-3);
      font-weight: var(--font-weight-2);
      margin-top: 30px;
    }
    .main-button,
    .main-button:hover,
    .main-button:active,
    .main-button:focus {
      background: linear-gradient(135deg, #0934c5 0%, #4e64e3 100%);
      color: var(--color-7);
      border: 1px solid transparent;
      cursor: pointer;
    }
  }
}

/* ============================== MOBILE, TABLET ============================== */

@include tablet_landscape() {
  .container {
    display: flex;
    flex-direction: column;
    grid-template-columns: 0;
    grid-column-gap: 0;
    grid-row-gap: 10px;

    .card {
      padding: 16px 24px;
      .title-card {
        font-size: 20px;
        font-weight: 700;
        line-height: 22px;
      }
      .wrap-content {
        line-height: 18px;
        .sub-title {
          font-weight: 600;
          font-size: 16px;
        }
        .content-text {
          font-weight: 400;
          font-size: 14px;
        }
      }
      .line {
        margin: 14px 0;
      }
    }

    .order-required {
      .form-right {
        padding-left: 0;
      }
    }

    .order-items {
      .table-items {
        .ant-table-tbody {
          font-size: 14px !important;
        }
      }
    }
  }
}

@include mobile_portrait() {
  .container {
    .order-items {
      .ant-table-tbody {
        font-size: 14px !important;
      }
    }
    .group-button {
      .button {
        margin: 0;
      }
    }
  }
}

.modal-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .title,
  .description {
    font-weight: 600;
  }
  .title {
    display: flex;
    align-items: center;
    span {
      padding-left: 6px;
    }
  }
  .description {
    font-weight: 400;
    padding-top: 10px;
  }
  .group-button {
    display: flex;
    padding-top: 10px;
    .button {
      margin: 0 8px;
      border-radius: 8px;
      border: initial;
      color: white;
      cursor: pointer;
    }
    .cancel {
      background: #afbbca;
    }
    .confirm {
      background: blue;
    }
  }
}
